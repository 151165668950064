import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatNumber, sortTableNumber } from '../../utils/generalFunctions';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { findMemberships } from '../../actions/memberships';

import DeleteMembership from './DeleteMembership';

const Memberships = () => {

    let breadcrumbRoutes = [
        {
            activo: true,
            name: 'Memberships',
            path: '/memberships'
        }
    ];

    const dispatch =  useDispatch();

    const { list } = useSelector(state => state.memberships);

    const [memberships, setMemberships] = useState([]);

    const [membershipDelete, setMembershipDelete] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const columns = [
        {
            name: 'Name',
            sortable: true, 
            selector: row => row.name
        },
        {
            name: 'Price',
            sortable: true, 
            sortFunction: (a, b) => sortTableNumber(a, b, 'price'),
            selector: row => row.price != null ? formatNumber(row.price, 2, '$ ', '') : 'Not defined'
        }, 
        {
            name: 'Description',
            sortable: true, 
            selector: row => row.description,
            grow: 2
        },  
        {
            name: 'Permissions',
            sortable: true, 
            sortFunction: (a, b) => sortTableNumber(a, b, 'permissions'),
            selector: row => row.permissions + '/' + row.total_permissions
        }, 
        {
            name: 'Companies',
            sortable: true, 
            sortFunction: (a, b) => sortTableNumber(a, b, 'companies'),
            selector: row => row.companies != null ? formatNumber(row.companies, 0, '', '') : 'Not defined'
        }, 
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDelete(row)}><i className="bi bi-x-lg text-danger"></i></button>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/memberships/edit/${row._id}`}><i className="bi bi-pencil-fill text-primary"></i></Link>
                    </div>
                )
            }
        }
    ];

    /* Delete */
    const handleDelete = (membership) => {
        setMembershipDelete(membership);
    }

    useEffect(() => {
        dispatch(findMemberships());
    }, []);

    useEffect(() => {
        setMemberships(list);
    }, [list]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <Fragment>

            <DeleteMembership membership={membershipDelete} restablishMembership={handleDelete} />

            <div className="row">
                <div className="col-md-12 py-4">

                    <div className="d-flex justify-content-end">
                        <Link to="/memberships/add">
                            <button className="btn btn-primary">Add membership</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={memberships}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Memberships;